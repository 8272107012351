import React from "react";
import { Trash, PencilSquare } from "react-bootstrap-icons";

const ExamDisp = ({ exam, setExamCrudModal, setType, setActiveExam }) => {
  return (
    <>
      <tr>
        <td>{exam.student.studentReg}</td>
        <td>
          {exam.student.firstname} {exam.student.lastname}{" "}
          {exam.student.surname}
        </td>
        <td>{exam.score}</td>
        <td>{exam.maxScore}</td>
        <td>{`${parseInt((exam.score * 100) / exam.maxScore)}%`}</td>
        <td>{exam.grade}</td>
        <td>{exam.comment}</td>
        <td>
          <PencilSquare
            onClick={() => {
              setExamCrudModal(true);
              setType("edit");
              setActiveExam({ ...exam });
            }}
            style={{ cursor: "pointer" }}
            className="text-info m-1"
          />
          <Trash
            onClick={() => {
              setExamCrudModal(true);
              setType("delete");
              setActiveExam({ ...exam });
            }}
            style={{ cursor: "pointer" }}
            className="text-danger m-1"
          />
        </td>
      </tr>
    </>
  );
};

export default ExamDisp;

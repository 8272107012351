import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addLivePayrollEntry } from "../../../Network/PayrollEntryApi";

const BulkPayslips = (props) => {
  //redux dispatch
  const dispatch = useDispatch();

  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const online = useSelector((state) => state.online.online);
  const User = useSelector((state) => state.auth.user);
  const [Loading, setLoading] = useState(false);
  const [Month, setMonth] = useState("");
  const [InputItems, setInputItems] = useState([]);

  useEffect(() => {
    setInputItems(props.items);
  }, [props.items]);

  //add cat
  const AddItem = () => {
    let linker = Date.now();

    InputItems.map((item, index) =>
      addLivePayrollEntry(
        {
          month: Month,
          staffLinker: item.staffLinker,
          itemLinker: item.linker,
          details: item.details,
          amount: item.amount,
          linker: linker + index,
          instLinker: ActiveCred.linker,
          credLinker: ActiveCred.linker,
          live: 1,
          status: 0,
          trace: linker,
          deleted: 0,
        },
        ActiveCred.instLinker,
        User.token,
        dispatch,
        setLoading,
        InputItems,
        index,
        props.setShowModal
      )
    );
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    AddItem();
  };

  return (
    <Modal
      show={props.ShowModal}
      onHide={() => props.setShowModal(false)}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="text-capitalize text-center">
            Generate Staffs Payslips <br />
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleSubmit}>
          <div className="form-group">
            <label className="mb-2 text-capitalize">Select Month:</label>
            <input
              className="rounded-pill form-control"
              value={Month}
              placeholder={`enter month`}
              onChange={(e) => setMonth(e.target.value)}
              type="month"
              required
            />
          </div>
          <div className="form-group">
            <p>
              <small>
                {
                  "{Amount Will Be Taken From Staffs Pay Items Hoping You Have Added For Each Staff, If Not go to each staff payroll and add them !!}"
                }
              </small>
            </p>
          </div>
          <div className="d-flex justify-content-around mb-2">
            {Loading ? (
              <span className="spinner-border text-primary"></span>
            ) : (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}
            <Button
              variant="secondary"
              type="button"
              onClick={() => props.setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BulkPayslips;

import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addLiveInterval, updateLiveInterval } from "../../Network/IntervalApi";

const IntervalCrud = (props) => {
  //redux dispatch
  const dispatch = useDispatch();

  const online = useSelector((state) => state.online.online);
  const User = useSelector((state) => state.auth.user);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const [Loading, setLoading] = useState(false);

  //add interval
  const AddInterval = async () => {
    let linker = Date.now();

    await addLiveInterval(
      {
        startAt: props.interval.startAt,
        linker,
        endAt: props.interval.endAt,
        instLinker: ActiveCred.linker,
        credLinker: ActiveCred.linker,
        live: 1,
        status: 0,
        trace: linker,
        deleted: 0,
      },
      ActiveCred.instLinker,
      User.token,
      dispatch
    );

    props.setInterval({
      ...props.interval,
      startAt: "",
      endAt: "",
    });
  };

  //edit interval
  const EditInterval = async () => {
    let { credLinker, trace, deleted } = props.interval;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    deleted = props.type === "delete" ? 1 : deleted;

    await updateLiveInterval(
      { ...props.interval, trace, credLinker, deleted },
      User.token,
      dispatch
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (props.type === "add") {
      await AddInterval();
    } else {
      await EditInterval();
    }
    setLoading(false);
    props.setShowModal(false);
  };

  return (
    <Modal
      show={props.ShowModal}
      onHide={() => props.setShowModal(false)}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="text-capitalize text-center">
            {props.type} Lesson Time Interval:
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleSubmit}>
          <div className="form-group">
            <label className="mb-2 text-capitalize">Class Starts At :</label>
            <input
              className="rounded form-control"
              placeholder={`enter start time`}
              value={props.interval.startAt}
              onChange={(e) =>
                props.setInterval({
                  ...props.interval,
                  startAt: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
              type="time"
            />
            <hr />
          </div>
          <div className="form-group">
            <label className="mb-2 text-capitalize">Class Ends At :</label>
            <input
              className="rounded form-control"
              placeholder={`enter end time`}
              value={props.interval.endAt}
              onChange={(e) =>
                props.setInterval({
                  ...props.interval,
                  endAt: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
              type="time"
            />
            <hr />
          </div>
          <div className="d-flex justify-content-around mb-2">
            {Loading ? (
              <span className="spinner-border text-primary"></span>
            ) : props.type === "delete" ? (
              <Button variant="danger" type="submit">
                Delete
              </Button>
            ) : (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}
            <Button
              variant="secondary"
              type="button"
              onClick={() => props.setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default IntervalCrud;

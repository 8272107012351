import React, { Component, lazy, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./css/App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import ResetPassword from "./Pages/Auth/ResetPassword";
import StoreEntries from "./Pages/Store/StoreEntries/StoreEntries";
import Items from "./Pages/Store/Items/Items";
import StoreCats from "./Pages/Store/StoreCats/StoreCats";
import Students from "./Pages/Students/Students";

import Profile from "./Pages/Auth/Profile";
import EditInst from "./Pages/Inst/EditInst";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Subjects from "./Pages/Admin/Subjects/Subjects";
import Activities from "./Pages/Admin/Activities/Activities";
import Staffs from "./Pages/Admin/Staffs/Staffs";

import Structures from "./Pages/Admin/Structures/Structures";
import Modes from "./Pages/Admin/Modes/Modes";
import Grades from "./Pages/Admin/Grades/Grades";
import Invoices from "./Pages/Finance/Invoices/Invoices";
import Payments from "./Pages/Finance/Payments/Payments";
import Exams from "./Pages/Academics/Exams/Exams";
import StudentAcademics from "./Pages/Students/StudentAcademics";
import FeeStatement from "./Pages/Students/FeeStatement";
import LibraryCats from "./Pages/Library/Categories/LibraryCats";
import Books from "./Pages/Library/Books/Books";
import StudentBooks from "./Pages/Students/StudentBooks";
import ExpenseCats from "./Pages/Finance/Expenses/Categories/ExpenseCats";
import Expenses from "./Pages/Finance/Expenses/Expenses/Expenses";
import MpesaOnline from "./Pages/Admin/OnlinePay/Mpesa/SetUp/MpesaOnline";
import MpesaPayments from "./Pages/Admin/OnlinePay/Mpesa/Payments/MpesaPayments";

import TimeTable from "./Pages/Timetable/TimeTable";
import PayollCats from "./Pages/Admin/Payroll/Categories/Cats";
import PayrollItems from "./Pages/Admin/Staffs/StaffPayroll/PayrollItems";
import PayrollSummary from "./Pages/Admin/Payroll/PayrollSummary";
import MyPaySlips from "./Pages/Auth/MyPaySlips";
import Notifications from "./Pages/Notifications/Notifications";
import BulkSms from "./Pages/Admin/Sms/BulkSms";
const Login = lazy(() => import("./Pages/Home/LogIn"));
const InstPrivacy = lazy(() => import("./Pages/Home/InstPrivacy"));
const InstTerms = lazy(() => import("./Pages/Home/InstTerms"));
const WebPay = lazy(() => import("./Pages/SubPay/WebPay"));
const WebPay2 = lazy(() => import("./Pages/SubPay2/WebPay2"));
const SoftwarePay = lazy(() => import("./Pages/SubPay/SoftwarePay"));
const SoftwarePay2 = lazy(() => import("./Pages/SubPay2/SoftwarePay2"));
const Terms = lazy(() => import("./Pages/Home/Terms"));
const Privacy = lazy(() => import("./Pages/Home/Privacy"));
const HomeDash = lazy(() => import("./Pages/Dashboard/HomeDash"));
const Inst = lazy(() => import("./Pages/Inst/Inst"));

class App extends Component {
	render() {
		return (
			<div>
				<BrowserRouter>
					<Suspense
						fallback={
							<div className="d-flex justify-content-center align-items-center">
								<span className="spinner-border text-primary"></span>
							</div>
						}
					>
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="/reset/:reset/:logger" element={<ResetPassword />} />
							<Route path="terms" element={<Terms />}></Route>
							<Route path="login" element={<Login />}></Route>
							<Route path="privacy" element={<Privacy />}></Route>
							<Route path="inst" element={<Inst />} />

							<Route path="activate" element={<SoftwarePay />} />
							{
								//Route to for version two of software payments
								<Route path="activate-software" element={<SoftwarePay2 />} />
							}

							<Route path=":instName" element={<Dashboard />}>
								<Route
									path="activate/:instName/:clientId"
									element={<WebPay />}
								></Route>
								{
									//Route for version two of web payments
									<Route
										path="activate-web/:instName/:clientId"
										element={<WebPay2 />}
									></Route>
								}
								<Route path="/:instName" element={<HomeDash />}></Route>
								<Route path="terms" element={<InstTerms />}></Route>
								<Route path="privacy" element={<InstPrivacy />}></Route>
								<Route path="edit" element={<EditInst />}></Route>
								<Route path="profile" element={<Profile />}></Route>
								<Route path="payslips" element={<MyPaySlips />}></Route>
								<Route
									path="structure/:structureType"
									element={<Structures />}
								></Route>
								<Route path="subjects" element={<Subjects />}></Route>
								<Route path="activities" element={<Activities />}></Route>
								<Route path="grading" element={<Grades />}></Route>
								<Route path="staffs" element={<Staffs />}></Route>
								<Route path="payroll-items" element={<PayollCats />}></Route>
								<Route
									path="staffs/payroll-items/:staffName/:staffNo/:staffLinker"
									element={<PayrollItems />}
								></Route>
								<Route
									path="payroll-summary"
									element={<PayrollSummary />}
								></Route>
								<Route path="notifications" element={<Notifications />}></Route>
								<Route path="bulk-sms" element={<BulkSms />}></Route>
								<Route path="students" element={<Students />}></Route>
								<Route path="modes" element={<Modes />}></Route>
								<Route
									path="modes/mpesa-setup"
									element={<MpesaOnline />}
								></Route>
								<Route path="invoices" element={<Invoices />}></Route>
								<Route path="payments" element={<Payments />}></Route>{" "}
								<Route
									path="mpesa-payments"
									element={<MpesaPayments />}
								></Route>
								<Route path="exams" element={<Exams />}></Route>
								<Route path="libcats" element={<LibraryCats />}></Route>
								<Route path="books" element={<Books />}></Route>
								<Route path="expensecats" element={<ExpenseCats />}></Route>
								<Route path="expenses" element={<Expenses />}></Route>
								<Route path="timetable" element={<TimeTable />}></Route>
								<Route path="store-categories" element={<StoreCats />}></Route>
								<Route path="store-items" element={<Items />}></Route>
								<Route path="store-entries" element={<StoreEntries />}></Route>
								<Route
									path="students/student/academics/:studentLinker"
									element={<StudentAcademics />}
								></Route>
								<Route
									path="students/student/finance/:studentLinker"
									element={<FeeStatement />}
								></Route>
								<Route
									path="students/student/books/:studentLinker"
									element={<StudentBooks />}
								></Route>
							</Route>

							{/*<Route path="*" element={<NotFound />} />*/}
						</Routes>
					</Suspense>
				</BrowserRouter>
			</div>
		);
	}
}

export default App;
